<template>
  <el-card class="box-card accountNum">
    <template #header>
      <div class="card-header">
        <span class="SubHead">
          可用余额
          <el-tooltip class="item" effect="light" content="" placement="top">
            <template #content>
              <span v-if="userInfo.credit != '0.00'">可用余额：扣除掉账户余额后，还可以用于账户充值的金额；</span>
              <span v-else>可用余额：充值到钱包里面的余额；</span>
              <br />
              账户余额：当前账户状态为正常的账户，账户余额的总和。
            </template>
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </span>
        <el-button type="text" class="routerBtn" @click="goRecharge"><span class="LinkText">钱包充值</span></el-button>
      </div>
    </template>

    <div class="text item">
      <el-row>
        <span class="Text" :class="numType == 1 ? 'red' : ''">可用余额：${{ $filters.thousands(balance) }}</span>
        <el-tooltip content="点击刷新余额" effect="light" placement="top">
          <el-button class="refreshBtn" type="text" @click="refreshBalance">
            <i class="iconfont icon-ylreplace"></i>
          </el-button>
        </el-tooltip>
      </el-row>
      <el-row>
        <span class="Text" :class="ccType == 1 ? 'red' : ''">账户余额：${{ $filters.thousands(accountBalance) }}</span>
      </el-row>
      <!-- this.credit_type = 2的时候显示 -->
      <el-row>
        <span class="Text" v-show="creditType == 2">授信额度：${{ $filters.thousands(creditBalance) }} </span>
      </el-row>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'UsableBalance',
  props: [],
  data() {
    return {
      userInfo: '',
      balance: '',
      realType: '',
      accountBalance: '',
      creditBalance: '',
      creditType: '',
      //控制可用余额变红
      numType: '',
      //控制账户余额变红
      ccType: '',
      //控制账户余额弹窗
      cType: '',
      //控制可用余额弹窗
      nType: '',
      idleBalance: '',
      taskNum: 0
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getUserBalance()
    this.getNewTask()
    this.$bus.on('getUserBalance', this.getUserBalance)
  },
  methods: {
    async getUserBalance() {
      const { data: res } = await this.$http.post('cloudfb/ad_money', {
        token: this.userInfo.token
      })
      this.balance = res.data.balance
      this.accountBalance = res.data.count
      this.creditBalance = res.data.credit
      this.creditType = res.data.credit_type
      this.numType = res.data.num_type
      this.ccType = res.data.cc_type
      this.cType = res.data.c_type
      this.nType = res.data.n_type
      this.accountBalancePop(this.cType)
      this.balancePop(this.nType)
    },
    // 获取新手任务数量
    async getNewTask() {
      const { data: res } = await this.$http.post('user/novice_task')
      this.taskNum = res.num
    },
    // 控制账户余额弹窗
    accountBalancePop(cType) {
      if (cType == 1) {
        this.$alert(
          `当前账户余额过多，其中闲置账户占用${this.idleBalance}的余额，可前往账户列表进行批量处理。`,
          '信息',
          {
            confirmButtonText: '收到',
            dangerouslyUseHTMLString: true
          }
        )
      }
    },

    // 控制可用余额弹窗
    balancePop(nType) {
      if (nType == 1) {
        this.$alert(`您的可用余额不足,请及时充值！`, '信息', {
          confirmButtonText: '收到',
          dangerouslyUseHTMLString: true
        })
      }
    },
    goRecharge() {
      // if(this.taskNum < 6 && this.userInfo.crm_type != 1) {
      //   this.$message.warning('请先完成右上角的新手任务')
      // }  else {
      this.$router.push('/recharge')
      // }
    },
    async refreshBalance() {
      const { data: res } = await this.$http.post('cloudfb/refresh_balance', {
        type: 1
      })
      if (res.code == 100) {
        return this.$message.error('刷新失败')
      }
      this.$message.success('刷新成功')
      this.getUserBalance()
    }
  }
}
</script>

<style lang="less" scoped>
i.el-icon-warning-outline {
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.routerBtn {
  padding: 0;
  min-height: auto;
}
a {
  text-decoration: none;
}
.accountNum {
  height: 180px;
}
.accountNum .el-row {
  margin-bottom: 8px;
}
.red {
  color: #f56c6c;
}
.refreshBtn {
  padding: 0;
  min-height: auto;
  margin-left: 8px;
}
</style>
