<template>
  <el-dialog title="营业执照校验" custom-class="createFB" width="800px" v-if="'legal_check' === dialogType" v-model="dialogShow"
    @close="$emit('close', '')">
    <el-alert title="帮助提示" description="" type="info" show-icon>
      <template #default>
        请先输入营业执照名称，校验该执照是否可以开户
        <el-link :underline="false" :href="`${base.url}/help/1062?type=1`" target="_blank" class="fillGuide">了解开户流程和准备
        </el-link>&nbsp;
        <el-link :underline="false" :href="`${base.url}/help/1063?type=1`" target="_blank" class="fillGuide">查看填写指南
        </el-link>
        <br />
        校验成功后，请务必使用已成功校验的执照名进行开户申请，否则将会无法成功收到开户进度通知<br />
        开户审核最快当天下户，最迟提交申请后第二天早上8点，您可随时在开户历史跟踪开户进度。
      </template>
    </el-alert>
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="rules">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="营业执照" prop="entity_name">
            <el-select v-model="form.entity_name" filterable default-first-option allow-create
              placeholder="请输入正确且完整的营业执照名称" size="small" style="width: 270px;">
              <el-option v-for="item in entityNameOptions" :key="item" :label="item" :value="item" />
            </el-select> </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="upload-row">
        <upload-file ref="uploadImgRef" :span="12" :action="`${base.admin_url}/cloud/file/uploadfile_tt`" :limit="1"
          :url="licenseImg" upload-text="点击上传营业执照" :preview-src-list="licenseImg" @handleRemove="licenseImg = ''"
          :editable="true" @handleSuccess="handleSuccess($event, true)" />
      </el-row>
      <el-row>
        <span class="uploadTip">注：支持通过上传文件识别出营业执照</span>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitLegal">确 定</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog title="联系方式" width="800px" v-if="'verify' === dialogType" v-model="dialogShow" @close="$emit('close', '')">
    <!-- 内容主体区域 -->
    <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="直客经理" prop="name">
            <el-input size="small" clearable v-model="form.name" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式" prop="contact">
            <el-input size="small" placeholder="手机号/微信号" clearable v-model="form.contact" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="BM ID" prop="bm_id">
            <el-input size="small" placeholder="" clearable v-model="form.bm_id" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitContact">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import base from "../../../request/base";
import { computed, getCurrentInstance, ref, onMounted } from "vue";
import { ElMessage } from "element-plus";
import api from "@/request/api/account";
import UploadFile from "@/views/userinfo/approve/client/UploadFile";

const emit = defineEmits(['close'])

const dialogShow = ref(true)
const dialogType = ref('legal_check')

const browserInfo = computed(() => {
  let sysObj = {}
  let browserInfo = navigator.userAgent.toLowerCase()
  let regular = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
  let vsMsg = browserInfo.match(regular)
  sysObj.browserName = vsMsg[1].replace(/version/, "'safari")
  sysObj.versionInfo = vsMsg[2]

  return `${sysObj.browserName}的版本是：${sysObj.versionInfo}`
})

const osInfo = computed(() => {
  let userAgent = navigator.userAgent.toLowerCase()
  let name = 'Unknown'
  let version = 'Unknown'
  if (userAgent.indexOf('win') > -1) {
    name = 'Windows'
    if (userAgent.indexOf('windows nt 5.0') > -1) {
      version = 'Windows 2000'
    } else if (userAgent.indexOf('windows nt 5.1') > -1 || userAgent.indexOf('windows nt 5.2') > -1) {
      version = 'Windows XP'
    } else if (userAgent.indexOf('windows nt 6.0') > -1) {
      version = 'Windows Vista'
    } else if (userAgent.indexOf('windows nt 6.1') > -1 || userAgent.indexOf('windows 7') > -1) {
      version = 'Windows 7'
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows 8') > -1) {
      version = 'Windows 8'
    } else if (userAgent.indexOf('windows nt 6.3') > -1) {
      version = 'Windows 8.1'
    } else if (userAgent.indexOf('windows nt 6.2') > -1 || userAgent.indexOf('windows nt 10.0') > -1) {
      version = 'Windows 10'
    } else {
      version = 'Unknown'
    }
  } else if (userAgent.indexOf('iphone') > -1) {
    name = 'Iphone'
  } else if (userAgent.indexOf('mac') > -1) {
    name = 'Mac'
  } else if (
    userAgent.indexOf('x11') > -1 ||
    userAgent.indexOf('unix') > -1 ||
    userAgent.indexOf('sunname') > -1 ||
    userAgent.indexOf('bsd') > -1
  ) {
    name = 'Unix'
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      name = 'Android'
    } else {
      name = 'Linux'
    }
  } else {
    name = 'Unknown'
  }

  return name === 'Windows' ? version : name
})

const formRef = ref(null)

const form = ref({
  entity_name: '',
  bm_id: '',
  height: window.innerHeight,
  width: window.innerWidth,
  browser: browserInfo.value,
  os: osInfo.value,
  legal_name: '',
  contact: '',
  name: ''
})

const licenseImg = ref('') // 营业执照图片
const entityNameOptions = ref([]) // 营业执照关联历史列表

const rules = ref({
  entity_name: [
    { required: true, message: '请输入营业执照名称', trigger: 'blur' },
    {
      pattern: /^[\u4e00-\u9fa5\(\)\（\）]{0,}$/,
      message: '只能使用中文营业执照开户',
      trigger: 'blur'
    }
  ],
  bm_id: [{ pattern: /^[0-9]*$/, message: 'BM ID格式不正确，应为纯数字', trigger: 'blur' },],
  name: [{ required: true, message: '请输入直客经理', trigger: 'blur' },]
})

const { proxy } = getCurrentInstance()

/** 上传营业执照识别回调 */
const handleSuccess = (res, updateCode = true, field = undefined) => {
  if (res.code === '100') ElMessage.error(res.msg)
  if (res.code === '200') {
    form.value.entity_name = res.license_name
  }
}

const submitLegal = () => {
  formRef.value.validate(async valid => {
    if (!valid) return
    const { data: res } = await proxy.$http.post('cloudfb/account_check', {
      entity_name: form.value.entity_name,
      height: form.value.height,
      width: form.value.width,
      browser: form.value.browser,
      os: form.value.os,
    })

    if (res.code == 100) {
      return ElMessage.error(`${res.msg}`)
    }

    if (res.code == 300) {
      proxy.$router.push('/login')
      return
    }

    if (res.code != 200) return

    if (res.type && 1 == res.type) {
      form.value.legal_name = form.value.entity_name
      dialogType.value = 'verify'

    } else {
      window.open(`https://www.facebook.com/chinabusinesses/onboarding/220398642106724/?token=${res.token}`)
      dialogType.value = ''
      emit('close', '')
    }
  })
}

const submitContact = () => {
  formRef.value.validate(async valid => {
    if (!valid) return
    const { data: res } = await proxy.$http.post('cloudfb/fill_sk', {
      legal_name: form.value.legal_name,
      contact: form.value.contact,
      name: form.value.name,
      bm_id: form.value.bm_id
    })

    dialogShow.value = false
    if (res.code == 100) {
      return ElMessage.error(`${res.msg}`)
    }

    if (res.code == 300) {
      proxy.$router.push('/login')
      return
    }
    ElMessage.success(`${res.msg}`)
    dialogShow.value = false
    dialogType.value = ''
  })
}

onMounted(() => {
  api.facebook.getHistoryLicense().then(res => {
    entityNameOptions.value = res.data
  })
})
</script>

<style lang="less" scoped>
.createFB .el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  margin-bottom: 20px;
}

.fillGuide {
  color: #2b82ff;
  vertical-align: baseline !important;
  margin-left: 5px;
}

.upload-row {
  padding-left: 78px;

  :deep(.el-upload--picture-card) {
    float: left;
    margin-right: 20px;
  }

  :deep(.el-upload-list__item) {
    width: 180px;
    height: 180px;
  }
}

.uploadTip {
  color: #262626;
  margin-top: 16px;
  margin-left: 78px;
}
</style>
